<template>
  <v-app>
    <v-app-bar height="30" color="#0056b3">
      <div style="margin:0 auto;display:block;">
        <v-btn small icon text color="white" href="https://fb.com/INMOGLOBALVE" target="_blank">
          <i class="fab fa-facebook-f"></i>
        </v-btn>
        <v-btn small icon text color="white" href="https://instagram.com/INMOGLOBALVE" target="_blank">
          <i class="fab fa-instagram"></i>
        </v-btn>
      </div>
    </v-app-bar>
    <v-app-bar
  class="menu-bar"
  flat
  height="40"
  style="background: transparent; margin-top: 60px; position: absolute; z-index: 10000;"
>
  <div class="menu-container">
    <v-btn class="mx-2"
      small
      text
      style="
        letter-spacing: 0;
        text-transform: none;
        font-size: 12px;
        color: white;
        text-shadow: 0px 0px 2px #000;
      "
      v-for="(item, i) in menu"
      :key="i"
      :to="item.link"
    >
      {{ item.name }}
    </v-btn>
  </div>
</v-app-bar>
    <video src="https://inmoglobalve.com.ve/videos/videoEntrada.mp4"
     style="width: 100%;"
     autoplay
     muted
     loop
     oncontextmenu="return false;">
      </video>
      <v-container class="pa-4 form-container" max-width="400px">
   
    <v-btn text icon @click="handleSubmit"
    style="background-color: #25d366;
    font-size: 1.5rem;
    color: white;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    padding: 15px;
    position: fixed;
    width: 50px;height: 50px;
    transition: background 0.3s ease;
    "
    >
      <i class="fab fa-whatsapp"></i>
    </v-btn>
  </v-container>
    <v-main>
      <router-view/>
    </v-main>
    <footers></footers>

  </v-app>
</template>
<style scoped>
.menu-container {
  margin: 0 auto;
  display: block;
  background: #0057b342;
  padding: 20px;
  border-radius: 10px;
}


</style>
<style>
.btns {
  color: #000;letter-spacing: 0;text-transform: none;
}
</style>
<script>
import footers from './components/footer.vue';
export default {
  name: 'App',

  data: () => ({
    contausActive:false,
    drawer:false,
    firstName: '',
      lastName: '',
      email: '',
      country: 'Venezuela',
      countries: ['Venezuela'], // Puedes agregar más países
      phoneType: '',
      phoneNumber: '',
      forWho: '',
    menu:[
    {name: 'Inicio', link:'/'},
    {name: 'Team Global', link:'/team-global'},
    {name: 'Contacto', link:'/contacto'},
    ]
  }),
  components:{
    footers
  },
  methods:{
    handleSubmit() {
      const whatsappUrl = `https://wa.me/584144123718`;
      window.open(whatsappUrl, '_blank');
    }
  }
};
</script>


<style scoped>
.form-container {
  margin: auto;
}

.title {
  font-weight: bold;
  font-size: 1.2rem;
}

.v-btn-toggle {
  width: 100%;
}

.orange--text {
  font-size: 0.8rem;
}
</style>