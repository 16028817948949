<template>
  <footer class="footer">
    <div class="footer-content">
      <div class="footer-left">
        <h2>Inmobiliaria Global</h2>
        <p>&copy; {{ currentYear }} Todos los derechos reservados</p>
      </div>
      <div class="footer-right">
        <p>Creado por <strong>Jesus Lucena</strong></p>
        <p>Teléfono: <a href="tel:+19146883125">+1(914)688-3125</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear() // Obtiene el año actual
    };
  }
};
</script>

<style scoped>
.footer {
  background-color: #00174A; /* Color de fondo azul oscuro */
  color: white; /* Texto blanco */
  padding: 20px; /* Espacio interno */
  position: relative; /* Posicionamiento */
  width: 100%; /* Ancho completo */
  box-shadow: 0 -2px 20px rgba(0, 0, 0, 0.3); /* Sombra superior */
  border-top: 4px solid #b91313; /* Borde superior naranja */
}

.footer-content {
  display: flex; /* Flexbox para alineación */
  justify-content: space-between; /* Espacio entre los elementos */
  align-items: center; /* Alineación vertical */
}

.footer-left h2 {
  margin: 0; /* Sin margen */
  font-size: 2rem; /* Tamaño de fuente grande */
  animation: fadeIn 1s; /* Animación de entrada */
}

.footer-left p,
.footer-right p {
  margin: 5px 0; /* Espaciado entre líneas */
  font-size: 1rem; /* Tamaño de fuente */
}

.footer-right a {
  color: #fff; /* Color naranja para enlaces */
  text-decoration: none; /* Sin subrayado */
  transition: color 0.3s; /* Transición suave para el color */
}

.footer-right a:hover {
  color: #b9131350; /* Cambia el color al pasar el mouse */
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px); /* Movimiento hacia arriba al entrar */
  }
  to {
    opacity: 1;
    transform: translateY(0); /* Posición original */
  }
}
</style>
