import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/team-global',
    name: 'team-global',
    component: () => import('../views/Team.vue')
  },
  {
    path: '/contacto',
    name: 'contacto',
    component: () => import('../views/Contacto.vue')
  },
  {
    path: '/resultados',
    name: 'resultados',
    component: () => import('../components/resultados.vue')
  },
  {
    path: '/detalleInmueble',
    name: 'detalleInmueble',
    component: () => import('../components/DetalleInmueble.vue')
  },
  {
    path: '/asesor',
    name: 'asesor',
    component: () => import('../components/Asesor.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
